.ApproversListSection {
  cursor: pointer;
}

.ApproversListSection .ApproversListFull {
  display: none;
  margin: -1em;
  padding: 1em;
  background: #f8f8f8;
  border: 1px solid #e8e8e8;
  position: absolute;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
  z-index: 99;
}

.ApproversListSection:hover .ApproversListShort {
  display: none;
}

.ApproversListSection:hover .ApproversListFull {
  display: block;
}

.OwnerListName {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.OwnerListName:hover {
  white-space: unset;
  position: absolute;
  margin: -1em;
  padding: 1em;
  background: #f8f8f8;
  border: 1px solid #e8e8e8;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
  z-index: 99;
}
