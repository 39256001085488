input[type='radio'],
/* input[type='checkbox'] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
} */

#migrations-manager-container {
  display: flex;
  flex-direction: column;
}

body {
  height: calc(100vh - 96px);
}
.mm__app {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  flex: 1;
  background-color: white;
  height: 100%;
}
.mm__app input[type='radio'],
/* .mm__app input[type='checkbox'] {
  margin: 0;
} */

.mm__app input[type='radio'] ~ *,
/* .mm__app input[type='checkbox'] ~ * {
  margin: 8px 4px;
} */
.mm__container__wrapper {
  padding: 1% 0;
  flex: 1;
  display: flex;
}

.mm__table {
  flex: 1;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.mm__table__cell {
  border-top: 1px solid #ddd;
  padding-left: 8px;
  padding-top: 8px;
  padding-right: 8px;
  font-size: 14px;
  /* height needs to be set via list so virtual scroller can expand */
  height: -webkit-fill-available;
  height: -moz-fill-available;
  height: stretch;
}
.mm__table__combined_cell {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
}

.mm__table__combined_row {
  flex: 3;
}

.mm__table--header {
  font-weight: 600;
  white-space: nowrap;
}
.mm__table__cell--dragger {
  padding-left: 0px;
  padding-right: 4px;
}
.mm__table__cell--attachment {
  background-color: #f9f9f9;
}
.mm__table__cell--attachment-name {
  padding-left: 32px;
}
.mm__table__cell--selected {
  background-color: #b2ceff;
}

section > table {
  width: 100%;
}

.form-buttons {
  display: flex;
}

.form-buttons :first-child {
  margin-right: 8px;
}

.form-buttons :last-child {
  margin-left: auto;
}

.flex {
  display: flex;
}

.flex--center {
  display: flex;
  align-items: center;
}

.flex--space-between {
  display: flex;
  justify-content: space-between;
}

.flex--column {
  display: flex;
  flex-direction: column;
}

.flex--reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex--full {
  display: flex;
  flex: 1;
}

.link--no-op {
  text-decoration: none;
}

.pl-0 {
  padding-left: 0px;
}

.pb-0_5 {
  padding-bottom: 0.5em;
}
.pt-1 {
  padding-top: 1em;
}
.p-4 {
  padding-top: 4em;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.list-style-none {
  list-style: none;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #afafaf;
}
.step {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
}

.step-body {
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  padding: 3%;
}

.step-actions {
  display: inline-flex;
  justify-content: space-between;
  margin: 0 2rem 1rem;
}

.step-button {
  padding: 0.5rem 1rem;
  border: none;
}

.stepper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.step-wrapper {
  width: 100%;
  height: 100%;
}

.stepper-selector {
  display: flex;
  flex: 1;
}

.stepper-progress {
  display: flex;
  z-index: 9;
}

.stepper-progress-wrapper {
  width: 90%;
  position: relative;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.step-title {
  text-align: center;
  font-size: 0.7rem;
  align-items: center;
  background: #fff;
  padding: 0 1rem;
  height: 30px;
}

.step-title-number {
  font-size: 1rem;
  background: #ceeeff;
  height: 24px;
  width: 24px;
  margin: auto;
  line-height: 1.5;
  border: 3px solid #fff;
  border-radius: 100%;
}

.stepper-progress-bar {
  position: absolute;
  height: 2px;
  top: 11px;
  background: #c4c4c4;
  transition: width 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.react-contextmenu-wrapper {
  flex: 1;
  display: flex;
}

.modal-overlay {
  z-index: 100;
}

/* Dot Pulse Loader */
.dot-pulse-container {
  width: 30px;
  margin: 0 16px;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #0c63ff;
  color: #0c63ff;
  box-shadow: 9999px 0 0 -4px #0c63ff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #0c63ff;
  color: #0c63ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -4px #0c63ff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -4px #0c63ff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -4px #0c63ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #0c63ff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -4px #0c63ff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -4px #0c63ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #0c63ff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -4px #0c63ff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -4px #0c63ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #0c63ff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -4px #0c63ff;
  }
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsis:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
  background: white;
  z-index: 99;
  padding-bottom: 1em;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.react-contexify__submenu {
  max-height: 40vh;
  overflow-y: scroll;
}

.inline-flex {
  display: inline-flex;
}

.auto-sizer-parent {
  height: 500px;
  overflow: auto;
}

.StatusCheckboxes {
  margin: 2em 1em 0 1em;
  border-radius: 4px;
  border: 1px solid #e0e0e0 !important;
  padding: 0.5em 0 0.75em 0;
}

.StatusCheckboxes label {
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.css-18s17si {
  width: 100%;
  margin-right: 1em;
  height: 100%;
}

.css-7wqvrz {
  min-width: 20%;
}

.StatusTag {
  flex-basis: 7em;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
}

.UploadRow {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0 0.25em;
}

select.DocumentContentSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='silver'><polygon points='0,0 8,0 4,4'/></svg>") no-repeat scroll 95% 60% transparent;
  padding: .25em .75em;
  border: 1px solid rgb(180, 206, 253);
  width: 100%;
}
